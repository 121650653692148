export const ACCOUNT_MARKETPLACE_LIST_LOAD_PENDING_STATUS =
  'ACCOUNT_MARKETPLACE_LIST_LOAD_PENDING_STATUS'
export const ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE =
  'ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE'
export const ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE_STATUS =
  'ACCOUNT_MARKETPLACE_LIST_LOAD_COMPLETE_STATUS'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_PENDING_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_PENDING_STATUS'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE_STATUS'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_ERROR =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_ERROR'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_PENDING_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_PENDING_STATUS'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE_STATUS'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_PENDING_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_PENDING_STATUS'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE_STATUS'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_MODAL =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_MODAL'

export const ACCOUNT_MWS_SYNC_SHOW_MODAL = 'ACCOUNT_MWS_SYNC_SHOW_MODAL'
export const ACCOUNT_MWS_SYNC_CLOSE_MODAL = 'ACCOUNT_MWS_SYNC_CLOSE_MODAL'
export const ACCOUNT_MWS_SYNC_SET_REGION = 'ACCOUNT_MWS_SYNC_SET_REGION'
export const ACCOUNT_MWS_SYNC_SET_MARKETPLACE =
  'ACCOUNT_MWS_SYNC_SET_MARKETPLACE'
export const ACCOUNT_MWS_SYNC_SET_SELLER_ID = 'ACCOUNT_MWS_SYNC_SET_SELLER_ID'
export const ACCOUNT_MWS_SYNC_SET_AUTH_TOKEN = 'ACCOUNT_MWS_SYNC_SET_AUTH_TOKEN'
export const ACCOUNT_MWS_SYNC_SET_ACCOUNT_NAME =
  'ACCOUNT_MWS_SYNC_SET_ACCOUNT_NAME'
export const ACCOUNT_MWS_SYNC_SET_SELLER_EMAIL =
  'ACCOUNT_MWS_SYNC_SET_SELLER_EMAIL'
export const ACCOUNT_MWS_SYNC_SET_DATA = 'ACCOUNT_MWS_SYNC_SET_DATA'
export const ACCOUNT_SELLER_SYNC_SET_DATA = 'ACCOUNT_SELLER_SYNC_SET_DATA'
export const ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STEP_SKIPPED =
  'ACCOUNT_MWS_SYNC_SET_PPC_SYNC_STEP_SKIPPED'
export const SELLER_ACCOUNT_SYNC_SHOW_MODAL = 'SELLER_ACCOUNT_SYNC_SHOW_MODAL'
export const SELLER_PPC_ACCOUNT_SYNC_SHOW_MODAL =
  'SELLER_PPC_ACCOUNT_SYNC_SHOW_MODAL'
export const SELLER_ACCOUNT_SYNC_CLOSE_MODAL = 'SELLER_ACCOUNT_SYNC_CLOSE_MODAL'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_PENDING_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_PENDING_STATUS'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE'
export const ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE_STATUS =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE_STATUS'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_STATUS_BAR =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_STATUS_BAR'

export const ACCOUNT_AMAZON_SELLER_ACCOUNT_TOGGLE_SYNC_DETAILS_MODAL =
  'ACCOUNT_AMAZON_SELLER_ACCOUNT_TOGGLE_SYNC_DETAILS_MODAL'

export const ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS =
  'ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS'
